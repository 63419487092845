<template>
  <section id="home" class="home-sec">
    <section class="header-sec">
      <v-container class="h-100">
        <v-row class="h-100">
          <img src="@/assets/img/dots.svg" class="img-fluid dots-img" />
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt w-80 h-100 d-flex justify-center">
              <div class="mainHeadTxt">
                Move On can help relieve your pain due to back pain, arthritis,
                shoulder pain, wrist pain, ankle pain, knee pain, muscle
                soreness, sprains and strains, aching joints, and bruises
              </div>
              <div class="subHeadTxt">
                <ul>
                  <li>
                    Cream is for elderlies suffering from chronic pain and
                    muscle ache
                  </li>
                  <li>
                    Gel for rapid relief with cooling effect targeting young
                    people who are exposed to accidental injuries and muscle
                    aches
                  </li>
                  <li>
                    Spray for easy application for those who do not tolerate or
                    do not like rubbing cream, it also provides instant relief,
                    Sprays can be excellent for hard-to-reach spots like your
                    back.
                  </li>
                  <li>
                    Freeze spray for athletes, fitness enthusiasts, and sporty
                    people who require cooling effect to relieve muscle spasm/
                    cramps and aches, easy to carry and apply within seconds ,
                    provides a highly pressurized force of cooling menthol that
                    quickly numbs the pain, low temperatures immediately relieve
                    swelling and pain
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="h-md-100 mt-4">
            <div class="w-100 h-100 d-flex justify-center align-center">
              <img src="@/assets/img/packs.jpg" class="img-fluid" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="Move-On-Cream py-5">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Move On Cream</div>
              <div class="subTitle mt-2">Active Ingredients</div>
              <div class="subTitleTxt">
                Menthol, Petrolatum, Camphor, Butyrosepermum Parkii (Shea
                Butter), Beeswax, Sorbitan Sesquioleate, Polyacrylamide, C13-14
                Isoparaffin, Methylparaben Allantoin, Gaultheria Procumbens
                (Wintergreen) Leaf Extract, Ecalyptus Globulus Leaf Extract.
              </div>

              <div class="numTxt d-flex justify-end">01</div>
              <hr />
              <div class="subTitle mt-2">Indications</div>
              <div class="subTitleTxt">
                For symptomatic relief from joint pains, inflammations, back
                pain, sprains, myositis, fibrositis and lumbago.
              </div>

              <div class="numTxt d-flex justify-end">02</div>
              <hr />
              <div class="subTitle mt-2">Directions for use</div>
              <div class="subTitleTxt">
                Massage move on cream into the affected area until completely
                absorbed.
                <br />
                Apply 2-3 times daily.
              </div>

              <div class="numTxt d-flex justify-end">03</div>
              <hr />
            </div>
          </v-col>
          <v-col md="6" cols="12" class="d-flex justify-center align-center">
            <div class="w-75">
              <img
                src="@/assets/img/Move On Cream2-min.png"
                class="img-fluid"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="Move-on-Spray py-5">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Move On Spray</div>
              <div class="subTitle mt-2">Active Ingredients</div>
              <div class="subTitleTxt">
                Menthol, Methyl salicylate, Camphor, Ecalyptus Globulus Leaf
                Oil, Propane & Butane.
              </div>

              <div class="numTxt d-flex justify-end">01</div>
              <hr />
              <div class="subTitle mt-2">Indications</div>
              <div class="subTitleTxt">
                For symptomatic relief from joint pain, arthritis, muscle
                inflammation.
              </div>

              <div class="numTxt d-flex justify-end">02</div>
              <hr />
              <div class="subTitle mt-2">Directions for use</div>
              <div class="subTitleTxt">
                Shake well before use.

                <br />
                Point spray towards the area of pain, holding the can about 10
                cm away from the affected area.
                <br />
                Spray in 2-3 short bursts.
              </div>

              <div class="numTxt d-flex justify-end">03</div>
              <hr />
              <div class="subTitle mt-2">Store below 30°C</div>
              <div class="subTitleTxt">Do not freeze.</div>

              <div class="numTxt d-flex justify-end">04</div>
              <hr />
            </div>
          </v-col>
          <v-col md="6" cols="12" class="d-flex justify-center align-center">
            <div>
              <img src="@/assets/img/Move on Spray-min.png" class="img-fluid" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="Move-on-Freeze-Spray py-5">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Move on Freeze Spray</div>
              <div class="subTitle mt-2">Active Ingredients</div>
              <div class="subTitleTxt">
                Menthol, Methyl salicylate, Camphor, Gaultheria Procumbens
                (Wintergreen) Leaf Extract, Ecalyptus Globulus Leaf Extract,
                Lavandula angustifolia (Lavender) Oil, Ethanol, Propane &
                Butane.
              </div>

              <div class="numTxt d-flex justify-end">01</div>
              <hr />
              <div class="subTitle mt-2">Indications</div>
              <div class="subTitleTxt">
                For symptomatic relief from joint pain, arthritis, muscle
                inflammation.
              </div>

              <div class="numTxt d-flex justify-end">02</div>
              <hr />
              <div class="subTitle mt-2">Directions for use</div>
              <div class="subTitleTxt">
                Shake well before use.

                <br />
                Point spray towards the area of pain, holding the can about 10
                cm away from the affected area.
                <br />
                Spray in 2-3 short bursts.
              </div>

              <div class="numTxt d-flex justify-end">03</div>
              <hr />
              <div class="subTitle mt-2">Store below 30°C</div>
              <div class="subTitleTxt">Do not freeze.</div>

              <div class="numTxt d-flex justify-end">04</div>
              <hr />
            </div>
          </v-col>
          <v-col md="6" cols="12" class="d-flex justify-center align-center">
            <div>
              <img
                src="@/assets/img/Move on freeze spray-min.png"
                class="img-fluid"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="Move-on-Freeze-Patch py-5">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Move On Freeze Patch</div>
              <div class="subTitle mt-2">Uses</div>
              <div class="subTitleTxt">
                Relieves minor pain associated with
                <ul>
                  <li>Muscular pain</li>
                  <li>Back pain</li>
                  <li>Joint pain</li>
                  <li>Backache</li>
                  <li>Bruise</li>
                  <li>Sprain</li>
                </ul>
              </div>

              <div class="numTxt d-flex justify-end">01</div>
              <hr />
              <div class="subTitle mt-2">For Best Results</div>
              <div class="subTitleTxt">
                <ul>
                  <li>
                    The cooling effect of the patch will be felt quickly and can
                    last up to 6 hours.
                  </li>
                  <li>A new patch may be applied as required.</li>
                  <li>
                    Do not use more than 4 patches one after the other on the
                    same area.
                  </li>
                  <li>
                    Cooling therapy should continue until pain or swelling has
                    reduced.
                  </li>
                </ul>
              </div>

              <div class="numTxt d-flex justify-end">02</div>
              <hr />
              <div class="subTitle mt-2">How to use</div>
              <div class="subTitleTxt">
                <ul>
                  <li>Apply only to clean and dry skin.</li>
                  <li>
                    Open the pouch, take a patch out of the pouch and peel off
                    the plastic cover film.
                  </li>
                  <li>Apply the sticky side of the patch on the pain areas.</li>
                  <li>
                    Reseal the pouch to prevent remaining from drying out.
                  </li>
                  <li>Use all patches within one month after opening pouch.</li>
                  <li>Each patch should be used once only.</li>
                </ul>
              </div>

              <div class="numTxt d-flex justify-end">03</div>
              <hr />
              <div class="subTitle mt-2">Storage condition</div>
              <div class="subTitleTxt">
                <ul>
                  <li>Avoid storing product in direct sunlight.</li>
                  <li>Store at room temperature (1~30°C).</li>
                </ul>
              </div>
              <div class="numTxt d-flex justify-end">04</div>
              <hr />
            </div>
          </v-col>
          <v-col md="6" cols="12" class="d-flex justify-center align-center">
            <div>
              <img
                src="@/assets/img/Move On Freeze Patch-min.png"
                class="img-fluid"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="Move-on-Capsicum-Hot-Patch py-5">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="6" cols="12" class="h-md-100">
            <div class="conOfheadTxt h-100 d-flex justify-center">
              <div class="titleTxt mb-2">Move on Capsicum-Hot Patch</div>
              <div class="subTitle mt-2">Ingredients</div>
              <div class="subTitleTxt">Capsicum extract as Capsaicin.</div>

              <div class="numTxt d-flex justify-end">01</div>
              <hr />
              <div class="subTitle mt-2">Uses</div>
              <div class="subTitleTxt">
                Relieves minor pain associated with:
                <ul>
                  <li>Muscular aches</li>
                  <li>Back pain</li>
                  <li>Arthritis</li>
                  <li>Strains</li>
                  <li>Bruises</li>
                  <li>Sprains</li>
                </ul>
              </div>

              <div class="numTxt d-flex justify-end">02</div>
              <hr />
              <div class="subTitle mt-2">Directions:</div>
              <div class="subTitle mt-2">
                Adults and children above 12 years old
              </div>
              <div class="subTitleTxt">
                <ul>
                  <li>Clean and dry affected area.</li>
                  <li>Remove patch from film.</li>
                  <li>
                    Apply to affected area not more than 3 to 4 times daily for
                    7 days.
                  </li>
                  <li>
                    Remove patch from the skin after at most 8 hours
                    application.
                  </li>
                </ul>
              </div>
              <div class="subTitle mt-2">Children under 12 years old:</div>
              <div class="subTitleTxt">
                <ul>
                  <li>Consult a doctor.</li>
                </ul>
              </div>
              <div class="numTxt d-flex justify-end">03</div>
              <hr />
              <div class="subTitle mt-2">Storage condition</div>
              <div class="subTitleTxt">
                <ul>
                  <li>Avoid storing product in direct sunlight.</li>
                  <li>Store at room temperature (1~30°C).</li>
                </ul>
              </div>

              <div class="numTxt d-flex justify-end">04</div>
              <hr />
            </div>
          </v-col>
          <v-col md="6" cols="12" class="d-flex justify-center align-center">
            <div>
              <img
                src="@/assets/img/Move On Hot Patch2-min.png"
                class="img-fluid"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
export default {
  name: "Home",
  components: {},

  data() {
    return {
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slides: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move on freeze spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freeze Gel",
          cream: true,

          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },

        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move on spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          cream: true,
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Move On Freeze Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
      ],
    };
  },

  mounted() {
    /* setTimeout(() => {
      var el = this.$el.getElementsByClassName(this.$route.query.id)[0];
      el.scrollIntoView({ behavior: "smooth" });
      console.log(this.$route.query.id);
    }); */
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./_about.scss";
</style>
